import React from "react";
import { Page } from "../../components/Page";
import { Title } from "../../components/Title";
import { Content } from "../../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Contact } from "../../components/Contact";

const FeedbackPage = () => (
  <Page language="en" page="Contact">
    <Row>
      <Col>
        <Title>Contact</Title>

        <Content>
          You can react us by email via <Contact /> or contact city specific
          feedback channels directly:
        </Content>

        <Content as="ul">
          <li>
            <a href="https://www.hel.fi/helsinki/en/administration/participate/feedback">
              Helsinki
            </a>
          </li>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default FeedbackPage;
